var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm._isMobile())?_c('div',{staticClass:"header pc",class:_vm.$route.path === '/service' ||
      _vm.$route.path === '/enter' ||
      _vm.$route.path === '/'
        ? 'bg1'
        : _vm.$route.path === '/product'
        ? 'bg4'
        : 'bg2'},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.$route.path === '/' ||
          _vm.$route.path === '/service' ||
          _vm.$route.path === '/product'
            ? require('@/assets/logo.png')
            : require('@/assets/logo_bg.png')},on:{"click":function($event){return _vm.$router.push('/')}}})]),_c('div',{staticClass:"txt"},[_c('ul',_vm._l((_vm.list),function(item,index){return _c('li',{key:index,class:{ active: _vm.active === index },style:({
            color:
              _vm.$route.path === '/' ||
              _vm.$route.path === '/service' ||
              _vm.$route.path === '/product'
                ? '#FFFFFF'
                : '#333333',
          }),on:{"click":function($event){return _vm.togo(item.path, index)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)])]):_c('div',[_c('div',{staticClass:"moblie",class:_vm.$route.path === '/' || _vm.$route.path === '/enter'
          ? 'bg1'
          : _vm.$route.path === '/learning'
          ? 'bg3'
          : 'bg2'},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.$route.path === '/enter' ||
            _vm.$route.path === '/learning'
              ? require('@/assets/logo.png')
              : require('@/assets/logo_bg.png')},on:{"click":function($event){return _vm.$router.push('/enter')}}})]),(
          _vm.$route.path === '/' ||
          _vm.$route.path === '/enter' ||
          _vm.$route.path === '/learning'
        )?_c('img',{staticClass:"menu-icon",attrs:{"src":require("@/assets/moblie/icon-01.png")},on:{"click":_vm.popFlag}}):_c('img',{staticClass:"menu-icon",attrs:{"src":require("@/assets/moblie/icon-02.png")},on:{"click":_vm.popFlag}})]),(_vm.flag)?_c('div',{staticClass:"pop-box"},_vm._l((_vm.list2),function(item,index){return _c('div',{key:index,style:(_vm.active == index ? 'color:#619878' : ''),on:{"click":function($event){return _vm.togo(item.path, index)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0):_vm._e(),(_vm.flag)?_c('div',{staticClass:"mask",on:{"click":_vm.close}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }